import { Box, Container, Heading, useColorMode, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ImgTextModule from "../components/ImgTextModule";
import IntroComponent2colMonochrome from "../components/IntroComponent2colMonochrome";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";
import "../styles-privacy.css";
export default function Privacy() {
	const { setColorMode } = useColorMode();

	useEffect(() => {
		setColorMode("light");
	});
	return (
		<>
			<Helmet>
				<title>tokentus | Privacy</title>
			</Helmet>
			<ImgTextModule
				src="target.mp4"
				delay={0.3}
				duration={0.5}
				threshold={0.3}
				initialY={10}
				initialX={10}
				video={true}
				h="55vh"
				mediaOpacity={0.9}
				bgColor="brand.black">
				<Container variant="layoutContainer" mt={8}>
					<Box p={{ base: 0, lg: "8" }} py={8}>
						<FadeInAnimation
							threshold={0.4}
							delay={0.4}
							duration={0.7}
							initialX={0}
							initialY={-10}>
							<Text
								color="brand.white"
								fontWeight="normal"
								fontSize={{ base: "5xl", lg: "6xl" }}>
								Privacy.
							</Text>
						</FadeInAnimation>
					</Box>
				</Container>
			</ImgTextModule>

			<IntroComponent2colMonochrome
				version="thirds"
				headline="Legal Information."
				text1="Information and transparency for you."
				text2="How we treat your data."
			/>

			<Container
				variant={"layoutContainer"}
				px={{ base: 0, lg: "8" }}
				w={{ base: "100%", lg: "50%" }}
				ml={{ lg: "50%" }}>
				<Heading
					fontSize="4xl"
					fontWeight="normal"
					mb={4}
					color="brand.meddarkgray">
					1. Privacy at a glance
				</Heading>
				<Heading
					fontSize="2xl"
					fontWeight="normal"
					mb={4}
					color="brand.medgray">
					General information
				</Heading>
				<div className="privacy">
					<h1>Privacy Policy</h1>
					<h2>1. An overview of data protection</h2>
					<h3>General information</h3>{" "}
					<p>
						The following information will provide you with an easy
						to navigate overview of what will happen with your
						personal data when you visit this website. The term
						&ldquo;personal data&rdquo; comprises all data that can
						be used to personally identify you. For detailed
						information about the subject matter of data protection,
						please consult our Data Protection Declaration, which we
						have included beneath this copy.
					</p>
					<h3>Data recording on this website</h3>{" "}
					<h4>
						Who is the responsible party for the recording of data
						on this website (i.e., the &ldquo;controller&rdquo;)?
					</h4>{" "}
					<p>
						The data on this website is processed by the operator of
						the website, whose contact information is available
						under section &ldquo;Information about the responsible
						party (referred to as the &ldquo;controller&rdquo; in
						the GDPR)&rdquo; in this Privacy Policy.
					</p>{" "}
					<h4>How do we record your data?</h4>{" "}
					<p>
						We collect your data as a result of your sharing of your
						data with us. This may, for instance be information you
						enter into our contact form.
					</p>{" "}
					<p>
						Other data shall be recorded by our IT systems
						automatically or after you consent to its recording
						during your website visit. This data comprises primarily
						technical information (e.g., web browser, operating
						system, or time the site was accessed). This information
						is recorded automatically when you access this website.
					</p>{" "}
					<h4>What are the purposes we use your data for?</h4>{" "}
					<p>
						A portion of the information is generated to guarantee
						the error free provision of the website. Other data may
						be used to analyze your user patterns.
					</p>{" "}
					<h4>
						What rights do you have as far as your information is
						concerned?
					</h4>{" "}
					<p>
						You have the right to receive information about the
						source, recipients, and purposes of your archived
						personal data at any time without having to pay a fee
						for such disclosures. You also have the right to demand
						that your data are rectified or eradicated. If you have
						consented to data processing, you have the option to
						revoke this consent at any time, which shall affect all
						future data processing. Moreover, you have the right to
						demand that the processing of your data be restricted
						under certain circumstances. Furthermore, you have the
						right to log a complaint with the competent supervising
						agency.
					</p>{" "}
					<p>
						Please do not hesitate to contact us at any time if you
						have questions about this or any other data protection
						related issues.
					</p>
					<h3>Analysis tools and tools provided by third parties</h3>{" "}
					<p>
						There is a possibility that your browsing patterns will
						be statistically analyzed when your visit this website.
						Such analyses are performed primarily with what we refer
						to as analysis programs.
					</p>{" "}
					<p>
						For detailed information about these analysis programs
						please consult our Data Protection Declaration below.
					</p>
					<h2>2. Hosting and Content Delivery Networks (CDN)</h2>
					<p>
						We are hosting the content of our website at the
						following provider:
					</p>
					<h3>External Hosting</h3>{" "}
					<p>
						This website is hosted externally. Personal data
						collected on this website are stored on the servers of
						the host. These may include, but are not limited to, IP
						addresses, contact requests, metadata and
						communications, contract information, contact
						information, names, web page access, and other data
						generated through a web site.
					</p>{" "}
					<p>
						The external hosting serves the purpose of fulfilling
						the contract with our potential and existing customers
						(Art. 6(1)(b) GDPR) and in the interest of secure, fast,
						and efficient provision of our online services by a
						professional provider (Art. 6(1)(f) GDPR). If
						appropriate consent has been obtained, the processing is
						carried out exclusively on the basis of Art. 6 (1)(a)
						GDPR and &sect; 25 (1) TTDSG, insofar the consent
						includes the storage of cookies or the access to
						information in the user's end device (e.g., device
						fingerprinting) within the meaning of the TTDSG. This
						consent can be revoked at any time.
					</p>{" "}
					<p>
						Our host(s) will only process your data to the extent
						necessary to fulfil its performance obligations and to
						follow our instructions with respect to such data.
					</p>{" "}
					<p>We are using the following host(s):</p>
					<p>
						GSG UG
						<br />
						Mozartstra&szlig;e 16
						<br />
						26382 Wilhelmshaven
					</p>
					<h4>Data processing</h4>{" "}
					<p>
						We have concluded a data processing agreement (DPA) with
						the above-mentioned provider. This is a contract
						mandated by data privacy laws that guarantees that they
						process personal data of our website visitors only based
						on our instructions and in compliance with the GDPR.
					</p>
					<h3>Cloudflare</h3>{" "}
					<p>
						We use the &ldquo;Cloudflare&rdquo; service provided by
						Cloudflare Inc., 101 Townsend St., San Francisco, CA
						94107, USA. (hereinafter referred to as
						&ldquo;Cloudflare&rdquo;).
					</p>{" "}
					<p>
						Cloudflare offers a content delivery network with DNS
						that is available worldwide. As a result, the
						information transfer that occurs between your browser
						and our website is technically routed via
						Cloudflare&rsquo;s network. This enables Cloudflare to
						analyze data transactions between your browser and our
						website and to work as a filter between our servers and
						potentially malicious data traffic from the Internet. In
						this context, Cloudflare may also use cookies or other
						technologies deployed to recognize Internet users, which
						shall, however, only be used for the herein described
						purpose.
					</p>{" "}
					<p>
						The use of Cloudflare is based on our legitimate
						interest in a provision of our website offerings that is
						as error free and secure as possible (Art. 6(1)(f)
						GDPR).
					</p>{" "}
					<p>
						Data transmission to the US is based on the Standard
						Contractual Clauses (SCC) of the European Commission.
						Details can be found here:{" "}
						<a
							href="https://www.cloudflare.com/privacypolicy/"
							target="_blank"
							rel="noopener noreferrer">
							https://www.cloudflare.com/privacypolicy/
						</a>
						.
					</p>{" "}
					<p>
						For more information on Cloudflare&rsquo;s security
						precautions and data privacy policies, please follow
						this link:{" "}
						<a
							href="https://www.cloudflare.com/privacypolicy/"
							target="_blank"
							rel="noopener">
							https://www.cloudflare.com/privacypolicy/
						</a>
						.
					</p>
					<h4>Data processing</h4>{" "}
					<p>
						We have concluded a data processing agreement (DPA) with
						the above-mentioned provider. This is a contract
						mandated by data privacy laws that guarantees that they
						process personal data of our website visitors only based
						on our instructions and in compliance with the GDPR.
					</p>
					<h2>3. General information and mandatory information</h2>
					<h3>Data protection</h3>{" "}
					<p>
						The operators of this website and its pages take the
						protection of your personal data very seriously. Hence,
						we handle your personal data as confidential information
						and in compliance with the statutory data protection
						regulations and this Data Protection Declaration.
					</p>{" "}
					<p>
						Whenever you use this website, a variety of personal
						information will be collected. Personal data comprises
						data that can be used to personally identify you. This
						Data Protection Declaration explains which data we
						collect as well as the purposes we use this data for. It
						also explains how, and for which purpose the information
						is collected.
					</p>{" "}
					<p>
						We herewith advise you that the transmission of data via
						the Internet (i.e., through email communications) may be
						prone to security gaps. It is not possible to completely
						protect data against third-party access.
					</p>
					<h3>
						Information about the responsible party (referred to as
						the &ldquo;controller&rdquo; in the GDPR)
					</h3>{" "}
					<p>The data processing controller on this website is:</p>{" "}
					<p>
						tokentus investment AG
						<br />
						Taunusanlage 8 c/o WeWork
						<br />
						60329 Frankfurt a. Main
					</p>
					<p>
						Phone: +49 175 7222 351
						<br />
						email: contact@tokentus.com
					</p>
					<p>
						The controller is the natural person or legal entity
						that single-handedly or jointly with others makes
						decisions as to the purposes of and resources for the
						processing of personal data (e.g., names, email
						addresses, etc.).
					</p>
					<h3>Storage duration</h3>{" "}
					<p>
						Unless a more specific storage period has been specified
						in this privacy policy, your personal data will remain
						with us until the purpose for which it was collected no
						longer applies. If you assert a justified request for
						deletion or revoke your consent to data processing, your
						data will be deleted, unless we have other legally
						permissible reasons for storing your personal data
						(e.g., tax or commercial law retention periods); in the
						latter case, the deletion will take place after these
						reasons cease to apply.
					</p>
					<h3>
						General information on the legal basis for the data
						processing on this website
					</h3>{" "}
					<p>
						If you have consented to data processing, we process
						your personal data on the basis of Art. 6(1)(a) GDPR or
						Art. 9 (2)(a) GDPR, if special categories of data are
						processed according to Art. 9 (1) DSGVO. In the case of
						explicit consent to the transfer of personal data to
						third countries, the data processing is also based on
						Art. 49 (1)(a) GDPR. If you have consented to the
						storage of cookies or to the access to information in
						your end device (e.g., via device fingerprinting), the
						data processing is additionally based on &sect; 25 (1)
						TTDSG. The consent can be revoked at any time. If your
						data is required for the fulfillment of a contract or
						for the implementation of pre-contractual measures, we
						process your data on the basis of Art. 6(1)(b) GDPR.
						Furthermore, if your data is required for the
						fulfillment of a legal obligation, we process it on the
						basis of Art. 6(1)(c) GDPR. Furthermore, the data
						processing may be carried out on the basis of our
						legitimate interest according to Art. 6(1)(f) GDPR.
						Information on the relevant legal basis in each
						individual case is provided in the following paragraphs
						of this privacy policy.
					</p>
					<h3>
						Information on data transfer to the USA and other non-EU
						countries
					</h3>{" "}
					<p>
						Among other things, we use tools of companies domiciled
						in the United States or other from a data protection
						perspective non-secure non-EU countries. If these tools
						are active, your personal data may potentially be
						transferred to these non-EU countries and may be
						processed there. We must point out that in these
						countries, a data protection level that is comparable to
						that in the EU cannot be guaranteed. For instance, U.S.
						enterprises are under a mandate to release personal data
						to the security agencies and you as the data subject do
						not have any litigation options to defend yourself in
						court. Hence, it cannot be ruled out that U.S. agencies
						(e.g., the Secret Service) may process, analyze, and
						permanently archive your personal data for surveillance
						purposes. We have no control over these processing
						activities.
					</p>
					<h3>
						Revocation of your consent to the processing of data
					</h3>{" "}
					<p>
						A wide range of data processing transactions are
						possible only subject to your express consent. You can
						also revoke at any time any consent you have already
						given us. This shall be without prejudice to the
						lawfulness of any data collection that occurred prior to
						your revocation.
					</p>
					<h3>
						Right to object to the collection of data in special
						cases; right to object to direct advertising (Art. 21
						GDPR)
					</h3>{" "}
					<p>
						IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF
						ART. 6(1)(E) OR (F) GDPR, YOU HAVE THE RIGHT TO AT ANY
						TIME OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA
						BASED ON GROUNDS ARISING FROM YOUR UNIQUE SITUATION.
						THIS ALSO APPLIES TO ANY PROFILING BASED ON THESE
						PROVISIONS. TO DETERMINE THE LEGAL BASIS, ON WHICH ANY
						PROCESSING OF DATA IS BASED, PLEASE CONSULT THIS DATA
						PROTECTION DECLARATION. IF YOU LOG AN OBJECTION, WE WILL
						NO LONGER PROCESS YOUR AFFECTED PERSONAL DATA, UNLESS WE
						ARE IN A POSITION TO PRESENT COMPELLING PROTECTION
						WORTHY GROUNDS FOR THE PROCESSING OF YOUR DATA, THAT
						OUTWEIGH YOUR INTERESTS, RIGHTS AND FREEDOMS OR IF THE
						PURPOSE OF THE PROCESSING IS THE CLAIMING, EXERCISING OR
						DEFENCE OF LEGAL ENTITLEMENTS (OBJECTION PURSUANT TO
						ART. 21(1) GDPR).
					</p>{" "}
					<p>
						IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO
						ENGAGE IN DIRECT ADVERTISING, YOU HAVE THE RIGHT TO
						OBJECT TO THE PROCESSING OF YOUR AFFECTED PERSONAL DATA
						FOR THE PURPOSES OF SUCH ADVERTISING AT ANY TIME. THIS
						ALSO APPLIES TO PROFILING TO THE EXTENT THAT IT IS
						AFFILIATED WITH SUCH DIRECT ADVERTISING. IF YOU OBJECT,
						YOUR PERSONAL DATA WILL SUBSEQUENTLY NO LONGER BE USED
						FOR DIRECT ADVERTISING PURPOSES (OBJECTION PURSUANT TO
						ART. 21(2) GDPR).
					</p>
					<h3>
						Right to log a complaint with the competent supervisory
						agency
					</h3>{" "}
					<p>
						In the event of violations of the GDPR, data subjects
						are entitled to log a complaint with a supervisory
						agency, in particular in the member state where they
						usually maintain their domicile, place of work or at the
						place where the alleged violation occurred. The right to
						log a complaint is in effect regardless of any other
						administrative or court proceedings available as legal
						recourses.
					</p>
					<h3>Right to data portability</h3>{" "}
					<p>
						You have the right to demand that we hand over any data
						we automatically process on the basis of your consent or
						in order to fulfil a contract be handed over to you or a
						third party in a commonly used, machine readable format.
						If you should demand the direct transfer of the data to
						another controller, this will be done only if it is
						technically feasible.
					</p>
					<h3>
						Information about, rectification and eradication of data
					</h3>{" "}
					<p>
						Within the scope of the applicable statutory provisions,
						you have the right to at any time demand information
						about your archived personal data, their source and
						recipients as well as the purpose of the processing of
						your data. You may also have a right to have your data
						rectified or eradicated. If you have questions about
						this subject matter or any other questions about
						personal data, please do not hesitate to contact us at
						any time.
					</p>
					<h3>Right to demand processing restrictions</h3>{" "}
					<p>
						You have the right to demand the imposition of
						restrictions as far as the processing of your personal
						data is concerned. To do so, you may contact us at any
						time. The right to demand restriction of processing
						applies in the following cases:
					</p>{" "}
					<ul>
						{" "}
						<li>
							In the event that you should dispute the correctness
							of your data archived by us, we will usually need
							some time to verify this claim. During the time that
							this investigation is ongoing, you have the right to
							demand that we restrict the processing of your
							personal data.
						</li>{" "}
						<li>
							If the processing of your personal data was/is
							conducted in an unlawful manner, you have the option
							to demand the restriction of the processing of your
							data in lieu of demanding the eradication of this
							data.
						</li>{" "}
						<li>
							If we do not need your personal data any longer and
							you need it to exercise, defend or claim legal
							entitlements, you have the right to demand the
							restriction of the processing of your personal data
							instead of its eradication.
						</li>{" "}
						<li>
							If you have raised an objection pursuant to Art.
							21(1) GDPR, your rights and our rights will have to
							be weighed against each other. As long as it has not
							been determined whose interests prevail, you have
							the right to demand a restriction of the processing
							of your personal data.
						</li>{" "}
					</ul>{" "}
					<p>
						If you have restricted the processing of your personal
						data, these data &ndash; with the exception of their
						archiving &ndash; may be processed only subject to your
						consent or to claim, exercise or defend legal
						entitlements or to protect the rights of other natural
						persons or legal entities or for important public
						interest reasons cited by the European Union or a member
						state of the EU.
					</p>
					<h3>SSL and/or TLS encryption</h3>{" "}
					<p>
						For security reasons and to protect the transmission of
						confidential content, such as purchase orders or
						inquiries you submit to us as the website operator, this
						website uses either an SSL or a TLS encryption program.
						You can recognize an encrypted connection by checking
						whether the address line of the browser switches from
						&ldquo;http://&rdquo; to &ldquo;https://&rdquo; and also
						by the appearance of the lock icon in the browser line.
					</p>{" "}
					<p>
						If the SSL or TLS encryption is activated, data you
						transmit to us cannot be read by third parties.
					</p>
					<h2>4. Recording of data on this website</h2>
					<h3>Cookies</h3>{" "}
					<p>
						Our websites and pages use what the industry refers to
						as &ldquo;cookies.&rdquo; Cookies are small data
						packages that do not cause any damage to your device.
						They are either stored temporarily for the duration of a
						session (session cookies) or they are permanently
						archived on your device (permanent cookies). Session
						cookies are automatically deleted once you terminate
						your visit. Permanent cookies remain archived on your
						device until you actively delete them, or they are
						automatically eradicated by your web browser.
					</p>{" "}
					<p>
						In some cases, it is possible that third-party cookies
						are stored on your device once you enter our site
						(third-party cookies). These cookies enable you or us to
						take advantage of certain services offered by the third
						party (e.g., cookies for the processing of payment
						services).
					</p>{" "}
					<p>
						Cookies have a variety of functions. Many cookies are
						technically essential since certain website functions
						would not work in the absence of the cookies (e.g., the
						shopping cart function or the display of videos). The
						purpose of other cookies may be the analysis of user
						patterns or the display of promotional messages.
					</p>{" "}
					<p>
						Cookies, which are required for the performance of
						electronic communication transactions, or for the
						provision of certain functions you want to use (e.g.,
						for the shopping cart function) or those that are
						necessary for the optimization (required cookies) of the
						website (e.g., cookies that provide measurable insights
						into the web audience), shall be stored on the basis of
						Art. 6(1)(f) GDPR, unless a different legal basis is
						cited. The operator of the website has a legitimate
						interest in the storage of required cookies to ensure
						the technically error free and optimized provision of
						the operator&rsquo;s services. If your consent to the
						storage of the cookies and similar recognition
						technologies has been requested, processing occurs
						exclusively on the basis of the consent obtained (Art.
						6(1)(a) GDPR and &sect; 25 (1) TTDSG); this consent may
						be revoked at any time.
					</p>{" "}
					<p>
						You have the option to set up your browser in such a
						manner that you will be notified any time cookies are
						placed and to permit the acceptance of cookies only in
						specific cases. You may also exclude the acceptance of
						cookies in certain cases or in general or activate the
						delete function for the automatic eradication of cookies
						when the browser closes. If cookies are deactivated, the
						functions of this website may be limited.
					</p>{" "}
					<p>
						In the event that third-party cookies are used or if
						cookies are used for analytical purposes, we will
						separately notify you in conjunction with this Data
						Protection Policy and, if applicable, ask for your
						consent.
					</p>
					<h3>Consent with Cookiebot</h3>{" "}
					<p>
						Our website uses consent technology from Cookiebot to
						obtain your consent to the storage of certain cookies on
						your end device or for the use of certain technologies
						and to document this in a data protection-compliant
						manner. The provider of this technology is Cybot A/S,
						Havnegade 39, 1058 Copenhagen, Denmark (hereinafter
						&ldquo;Cookiebot&rdquo;).
					</p>{" "}
					<p>
						When you enter our website, a connection is established
						with the Cookiebot servers to obtain your consent and
						provide you with other explanations regarding the use of
						cookies. Cookiebot will then store a cookie in your
						browser to identify the consent you have given or its
						revocation. The data collected in this way is stored
						until you request us to delete it, delete the Cookiebot
						cookie itself or the purpose for which the data is
						stored no longer applies. Mandatory legal storage
						obligations remain unaffected.
					</p>{" "}
					<p>
						Cookiebot is used to obtain the legally required consent
						for the use of cookies. The legal basis for this is Art.
						6(1)(c) GDPR.
					</p>
					<h3>Server log files</h3>{" "}
					<p>
						The provider of this website and its pages automatically
						collects and stores information in so-called server log
						files, which your browser communicates to us
						automatically. The information comprises:
					</p>{" "}
					<ul>
						{" "}
						<li>The type and version of browser used</li>{" "}
						<li>The used operating system</li> <li>Referrer URL</li>{" "}
						<li>The hostname of the accessing computer</li>{" "}
						<li>The time of the server inquiry</li>{" "}
						<li>The IP address</li>{" "}
					</ul>{" "}
					<p>This data is not merged with other data sources.</p>{" "}
					<p>
						This data is recorded on the basis of Art. 6(1)(f) GDPR.
						The operator of the website has a legitimate interest in
						the technically error free depiction and the
						optimization of the operator&rsquo;s website. In order
						to achieve this, server log files must be recorded.
					</p>
					<h3>Request by email, telephone, or fax</h3>{" "}
					<p>
						If you contact us by email, telephone or fax, your
						request, including all resulting personal data (name,
						request) will be stored and processed by us for the
						purpose of processing your request. We do not pass these
						data on without your consent.
					</p>{" "}
					<p>
						These data are processed on the basis of Art. 6(1)(b)
						GDPR if your inquiry is related to the fulfillment of a
						contract or is required for the performance of
						pre-contractual measures. In all other cases, the data
						are processed on the basis of our legitimate interest in
						the effective handling of inquiries submitted to us
						(Art. 6(1)(f) GDPR) or on the basis of your consent
						(Art. 6(1)(a) GDPR) if it has been obtained; the consent
						can be revoked at any time.
					</p>{" "}
					<p>
						The data sent by you to us via contact requests remain
						with us until you request us to delete, revoke your
						consent to the storage or the purpose for the data
						storage lapses (e.g. after completion of your request).
						Mandatory statutory provisions - in particular statutory
						retention periods - remain unaffected.
					</p>
					<h2>5. Analysis tools and advertising</h2>
					<h3>Google Analytics</h3>{" "}
					<p>
						This website uses functions of the web analysis service
						Google Analytics. The provider of this service is Google
						Ireland Limited (&ldquo;Google&rdquo;), Gordon House,
						Barrow Street, Dublin 4, Ireland.
					</p>{" "}
					<p>
						Google Analytics enables the website operator to analyze
						the behavior patterns of website visitors. To that end,
						the website operator receives a variety of user data,
						such as pages accessed, time spent on the page, the
						utilized operating system and the user&rsquo;s origin.
						This data is assigned to the respective end device of
						the user. An assignment to a user-ID does not take
						place.
					</p>{" "}
					<p>
						Google Analytics uses technologies that make the
						recognition of the user for the purpose of analyzing the
						user behavior patterns (e.g., cookies or device
						fingerprinting). The website use information recorded by
						Google is, as a rule transferred to a Google server in
						the United States, where it is stored.
					</p>{" "}
					<p>
						The use of these services occurs on the basis of your
						consent pursuant to Art. 6(1)(a) GDPR and &sect; 25(1)
						TTDSG. You may revoke your consent at any time.
					</p>{" "}
					<p>
						Data transmission to the US is based on the Standard
						Contractual Clauses (SCC) of the European Commission.
						Details can be found here:{" "}
						<a
							href="https://privacy.google.com/businesses/controllerterms/mccs/"
							target="_blank"
							rel="noopener noreferrer">
							https://privacy.google.com/businesses/controllerterms/mccs/
						</a>
						.
					</p>{" "}
					<h4>IP anonymization</h4>{" "}
					<p>
						On this website, we have activated the IP anonymization
						function. As a result, your IP address will be
						abbreviated by Google within the member states of the
						European Union or in other states that have ratified the
						Convention on the European Economic Area prior to its
						transmission to the United States. The full IP address
						will be transmitted to one of Google&rsquo;s servers in
						the United States and abbreviated there only in
						exceptional cases. On behalf of the operator of this
						website, Google shall use this information to analyze
						your use of this website to generate reports on website
						activities and to render other services to the operator
						of this website that are related to the use of the
						website and the Internet. The IP address transmitted in
						conjunction with Google Analytics from your browser
						shall not be merged with other data in Google&rsquo;s
						possession.
					</p>
					<h4>Browser plug-in</h4>{" "}
					<p>
						You can prevent the recording and processing of your
						data by Google by downloading and installing the browser
						plugin available under the following link:{" "}
						<a
							href="https://tools.google.com/dlpage/gaoptout?hl=en"
							target="_blank"
							rel="noopener noreferrer">
							https://tools.google.com/dlpage/gaoptout?hl=en
						</a>
						.
					</p>{" "}
					<p>
						For more information about the handling of user data by
						Google Analytics, please consult Google&rsquo;s Data
						Privacy Declaration at:{" "}
						<a
							href="https://support.google.com/analytics/answer/6004245?hl=en"
							target="_blank"
							rel="noopener noreferrer">
							https://support.google.com/analytics/answer/6004245?hl=en
						</a>
						.
					</p>
					<h4>Demographic parameters provided by Google Analytics</h4>{" "}
					<p>
						This website uses the &ldquo;demographic
						characteristics&rdquo; function of Google Analytics, to
						be able to display to the website visitor compatible ads
						within the Google advertising network. This allows
						reports to be created that contain information about the
						age, gender, and interests of the website visitors. The
						sources of this information are interest-related
						advertising by Google as well as visitor data obtained
						from third-party providers. This data cannot be
						allocated to a specific individual. You have the option
						to deactivate this function at any time by making
						pertinent settings changes for advertising in your
						Google account or you can generally prohibit the
						recording of your data by Google Analytics as explained
						in section &ldquo;Objection to the recording of
						data&rdquo;.
					</p>
					<h4>Contract data processing</h4>{" "}
					<p>
						We have executed a contract data processing agreement
						with Google and are implementing the stringent
						provisions of the German data protection agencies to the
						fullest when using Google Analytics.
					</p>
					<h2>
						6. Online-based Audio and Video Conferences (Conference
						tools)
					</h2>
					<h4>Data processing</h4>{" "}
					<p>
						We use online conference tools, among other things, for
						communication with our customers. The tools we use are
						listed in detail below. If you communicate with us by
						video or audio conference using the Internet, your
						personal data will be collected and processed by the
						provider of the respective conference tool and by us.
						The conferencing tools collect all information that you
						provide/access to use the tools (email address and/or
						your phone number). Furthermore, the conference tools
						process the duration of the conference, start and end
						(time) of participation in the conference, number of
						participants and other &ldquo;context information&rdquo;
						related to the communication process (metadata).
					</p>{" "}
					<p>
						Furthermore, the provider of the tool processes all the
						technical data required for the processing of the online
						communication. This includes, in particular, IP
						addresses, MAC addresses, device IDs, device type,
						operating system type and version, client version,
						camera type, microphone or loudspeaker and the type of
						connection.
					</p>{" "}
					<p>
						Should content be exchanged, uploaded, or otherwise made
						available within the tool, it is also stored on the
						servers of the tool provider. Such content includes, but
						is not limited to, cloud recordings, chat/ instant
						messages, voicemail uploaded photos and videos, files,
						whiteboards, and other information shared while using
						the service.
					</p>{" "}
					<p>
						Please note that we do not have complete influence on
						the data processing procedures of the tools used. Our
						possibilities are largely determined by the corporate
						policy of the respective provider. Further information
						on data processing by the conference tools can be found
						in the data protection declarations of the tools used,
						and which we have listed below this text.
					</p>{" "}
					<h4>Purpose and legal bases</h4>{" "}
					<p>
						The conference tools are used to communicate with
						prospective or existing contractual partners or to offer
						certain services to our customers (Art. 6(1)(b) GDPR).
						Furthermore, the use of the tools serves to generally
						simplify and accelerate communication with us or our
						company (legitimate interest in the meaning of Art.
						6(1)(f) GDPR). Insofar as consent has been requested,
						the tools in question will be used on the basis of this
						consent; the consent may be revoked at any time with
						effect from that date.
					</p>{" "}
					<h4>Duration of storage</h4>{" "}
					<p>
						Data collected directly by us via the video and
						conference tools will be deleted from our systems
						immediately after you request us to delete it, revoke
						your consent to storage, or the reason for storing the
						data no longer applies. Stored cookies remain on your
						end device until you delete them. Mandatory legal
						retention periods remain unaffected.
					</p>{" "}
					<p>
						We have no influence on the duration of storage of your
						data that is stored by the operators of the conference
						tools for their own purposes. For details, please
						directly contact the operators of the conference tools.
					</p>{" "}
					<h4>Conference tools used</h4>{" "}
					<p>We employ the following conference tools:</p>
					<h3>Zoom</h3>{" "}
					<p>
						We use Zoom. The provider of this service is Zoom
						Communications Inc, San Jose, 55 Almaden Boulevard, 6th
						Floor, San Jose, CA 95113, USA. For details on data
						processing, please refer to Zoom&rsquo;s privacy policy:{" "}
						<a
							href="https://zoom.us/en-us/privacy.html"
							target="_blank"
							rel="noopener noreferrer">
							https://zoom.us/en-us/privacy.html
						</a>
						.
					</p>{" "}
					<p>
						Data transmission to the US is based on the Standard
						Contractual Clauses (SCC) of the European Commission.
						Details can be found here:{" "}
						<a
							href="https://zoom.us/de-de/privacy.html"
							target="_blank"
							rel="noopener noreferrer">
							https://zoom.us/de-de/privacy.html
						</a>
						.
					</p>
				</div>
				<Box mt={24}></Box>
			</Container>
		</>
	);
}
